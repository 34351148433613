export const TRANSLATIONS = {
  en: {
    translation: {
      Home: "Home",
      Portfolio: "Portfolio",
      Testimonials: "Testimonials",
      Contact: "Contact",
      TimeToShine: "It's your time to shine",
      TimeToShineDescription:
        "Enhance your presence with OutrankThem: Expertise in Reputation Management, Digital Marketing, and Business Automation. Connect with us to achieve strategic growth.",
      GetStarted: "Get Started",
      SpeakToSales: "Speak to Sales",
      ReputationManagement: "Reputation Management",
      IncreaseReviews: "Increase your customer reviews",
      ExtractInsights: "Extract key insights from reviews",
      KeepListingUpToDate: "Keep your business listing up to date",
      AnswerCustomers: "Answer your customer's questions 24/7",
      DigitalMarketing: "Digital Marketing",
      SEO: "Search Engine Optimization (SEO)",
      Ads: "Promote on Google and Meta",
      ContentGeneration: "Generate engaging content",
      EmailSMS: "Email and SMS Marketing",
      BusinessAutomation: "Business Automation",
      GenAI: "Develop Generative AI solutions",
      POS: "Setup point of sale (POS) system",
      OnlineBooking: "Implement online booking solution",
      InventoryManagement: "Install Inventory Management System",
      OurStory: "Our Story",
      OurStoryP1:
        "Founded by two expert technologists, OutrankThem brings together over 20 years of experience from leading tech roles in major  corporations and startups across the USA and Canada. Our core lies in solving complex business problems with creative software solutions, anchored by a data-driven approach for measurable outcomes.",
      OurStoryP2:
        "We're committed to propelling your business forward, ensuring you stay ahead in the competitive landscape.",
      TrustedByClients: "Trusted by the world’s most innovative teams",
      PaTestimonialTitle: "Transforming Challenges into Triumphs",
      PaTestimonialP1:
        "As CEO of Parts Avatar, I'm immensely grateful to OutrankThem for their significant contributions over the last 10 months. As we navigated through a critical phase aiming for the sale of the company, their innovative solutions have significantly enhanced our offerings and boosted our web traffic.",
      PaTestimonialP2:
        "Their mastery in simplifying complex problems, evidenced by the ingenious fitment notes project and a superior search technology overhaul, not only matched but exceeded the standards of industry giants. The strategic SEO campaign that significantly improved our online visibility has made our offerings irresistibly compelling to our clients.",
      PaTestimonialP3:
        "Thank you, OutrankThem, for being an invaluable part of our journey towards success. Your partnership has been a key factor in our achievements.",
      CeoPartsAvatar: "CEO of Parts Avatar",
      CapTestimonialTitle: "Digital Renaissance",
      CapTestimonialP1:
        "As Managing Director at Mont Spartel, I'm profoundly appreciative of the transformative impact OutrankThem has made on the Cap Spartel lighthouse's digital presence. Their comprehensive revamp of our website to include multiple languages, alongside a significant design and SEO upgrade, has garnered 36,000 impressions and propelled us into the top 5 Google organic search rankings.",
      CapTestimonialP2:
        "Their meticulous management of our online reputation, including audits, review responses, and enriched content with photos and videos, has revitalized our image and attracted more visitors.",
      CapTestimonialP3:
        "Moreover, the launch of our new Instagram account, with superior photography and content, has significantly increased engagement, marking a new era of digital interaction for Mont Spartel",
      ManagingDirectorMontSpartel: "Managing Director at Mont Spartel",
      PaBlogPostOverview:
        "Explore how Parts Avatar's strategic partnership with OutrankThem led to unprecedented growth and digital innovation in our latest blog post.",
      ReadMore: "Read more",
      DigitalRenaissancePt1: "Digital Renaissance Part 1",
      DigitalRenaissancePt2: "Digital Renaissance Part 2",
      CapBlogPostOverviewPt1:
        "Dive into our blog post on how OutrankThem's strategic reputation management and digital enhancement for Cap Spartel lighthouse turned visitor experiences and online presence into a beacon of success.",
      CapBlogPostOverviewPt2:
        "Discover in our blog how OutrankThem's mastery in digital marketing, website revamp, and dynamic Instagram creation propelled a brand's online identity and engagement to new heights.",
      ContactDescription:
        "Facing a challenge? Seeking insights on our innovative solutions? Interested in our tailored business strategies? Reach out to us.",
      ContactSubject: "Subject",
      ContactYourMessage: "How can we help you?",
      ContactSubmit: "Submit",
      ContactSubmitting: "Submitting...",
      ContactSubmitted: "Your request has been submitted successfully!",
      AllRightsReserved: "All rights reserved",
    },
  },
  es: {
    translation: {
      Home: "Inicio",
      Portfolio: "Portafolio",
      Testimonials: "Testimonios",
      Contact: "Contacto",
      TimeToShine: "Es tu momento de brillar",
      TimeToShineDescription:
        "Mejora tu presencia con OutrankThem: Expertos en gestión de reputación, marketing digital y automatización de negocios. Conéctate con nosotros para lograr un crecimiento estratégico.",
      GetStarted: "Empezar",
      SpeakToSales: "Hablar con ventas",
      ReputationManagement: "Gestión de reputación",
      IncreaseReviews: "Aumenta tus comentarios de clientes",
      ExtractInsights: "Extrae ideas clave de las reseñas",
      KeepListingUpToDate: "Mantén tu listado de negocios actualizado",
      AnswerCustomers: "Responde a las preguntas de tus clientes 24/7",
      DigitalMarketing: "Marketing digital",
      SEO: "Optimización de motores de búsqueda (SEO)",
      Ads: "Promociona en Google y Meta",
      ContentGeneration: "Genera contenido atractivo",
      EmailSMS: "Marketing por correo electrónico y SMS",
      BusinessAutomation: "Automatización de negocios",
      GenAI: "Desarrolla soluciones de IA generativas",
      POS: "Configura un sistema de punto de venta (POS)",
      OnlineBooking: "Implementa una solución de reserva en línea",
      InventoryManagement: "Instala un sistema de gestión de inventario",
      OurStory: "Nuestra historia",
      OurStoryP1:
        "Fundada por dos expertos tecnólogos, OutrankThem reúne más de 20 años de experiencia en roles técnicos líderes en grandes corporaciones y startups en los Estados Unidos y Canadá. Nuestro núcleo radica en resolver problemas comerciales complejos con soluciones de software creativas, respaldadas por un enfoque basado en datos para resultados medibles.",
      OurStoryP2:
        "Estamos comprometidos a impulsar tu negocio hacia adelante, asegurándote de mantenerte a la vanguardia en el panorama competitivo.",
      TrustedByClients: "Confiado por los equipos más innovadores del mundo",
      PaTestimonialTitle: "Transformando desafíos en triunfos",
      PaTestimonialP1:
        "Como CEO de Parts Avatar, estoy inmensamente agradecido a OutrankThem por sus significativas contribuciones durante los últimos 10 meses. A medida que navegamos a través de una fase crítica con el objetivo de vender la empresa, sus soluciones innovadoras han mejorado significativamente nuestras ofertas y han impulsado el tráfico web.",
      PaTestimonialP2:
        "Su maestría en simplificar problemas complejos, evidenciada por el ingenioso proyecto de notas de ajuste y una revisión superior de la tecnología de búsqueda, no solo igualó sino que superó los estándares de los gigantes de la industria. La campaña estratégica de SEO que mejoró significativamente nuestra visibilidad en línea ha hecho que nuestras ofertas sean irresistiblemente atractivas para nuestros clientes.",
      PaTestimonialP3:
        "Gracias, OutrankThem, por ser una parte invaluable de nuestro viaje hacia el éxito. Tu asociación ha sido un factor clave en nuestros logros.",
      CeoPartsAvatar: "CEO de Parts Avatar",
      CapTestimonialTitle: "Renacimiento digital",
      CapTestimonialP1:
        "Como Director Gerente en Mont Spartel, estoy profundamente agradecido por el impacto transformador que OutrankThem ha tenido en la presencia digital del faro de Cap Spartel. Su renovación completa de nuestro sitio web para incluir varios idiomas, junto con una mejora significativa en el diseño y SEO, ha obtenido 36,000 impresiones y nos ha impulsado a los primeros 5 rankings de búsqueda orgánica de Google.",
      CapTestimonialP2:
        "Su meticulosa gestión de nuestra reputación en línea, incluyendo auditorías, respuestas a reseñas y contenido enriquecido con fotos y videos, ha revitalizado nuestra imagen y atraído a más visitantes.",
      CapTestimonialP3:
        "Además, el lanzamiento de nuestra nueva cuenta de Instagram, con fotografía superior y contenido, ha aumentado significativamente la interacción, marcando una nueva era de interacción digital para Mont Spartel.",
      ManagingDirectorMontSpartel: "Director Gerente en Mont Spartel",
      PaBlogPostOverview:
        "Explora cómo la asociación estratégica de Parts Avatar con OutrankThem condujo a un crecimiento sin precedentes e innovación digital en nuestra última publicación de blog.",
      ReadMore: "Leer más",
      DigitalRenaissancePt1: "Renacimiento digital Parte 1",
      DigitalRenaissancePt2: "Renacimiento digital Parte 2",
      CapBlogPostOverviewPt1:
        "Sumérgete en nuestra publicación de blog sobre cómo la gestión estratégica de la reputación y la mejora digital de OutrankThem para el faro de Cap Spartel transformaron las experiencias de los visitantes y la presencia en línea en un faro de éxito.",
      CapBlogPostOverviewPt2:
        "Descubre en nuestro blog cómo la maestría de OutrankThem en marketing digital, renovación de sitios web y creación dinámica de Instagram impulsó la identidad en línea y el compromiso de una marca a nuevas alturas.",
      ContactDescription:
        "¿Enfrentando un desafío? ¿Buscas información sobre nuestras soluciones innovadoras? ¿Interesado en nuestras estrategias de negocio personalizadas? Contáctanos.",
      ContactSubject: "Asunto",
      ContactYourMessage: "¿Cómo podemos ayudarte?",
      ContactSubmit: "Enviar",
      ContactSubmitting: "Enviando...",
      ContactSubmitted: "¡Tu solicitud se ha enviado con éxito!",
      AllRightsReserved: "Todos los derechos reservados",
    },
  },
  fr: {
    translation: {
      Home: "Acceuil",
      Portfolio: "Portfolio",
      Testimonials: "Témoignages",
      Contact: "Contact",
      TimeToShine: "Il est temps de briller",
      TimeToShineDescription:
        "Renforcez votre présence avec OutrankThem: expertise en gestion de la réputation, marketing digital, et automatisation des entreprises. Contactez-nous pour atteindre une croissance stratégique.",
      GetStarted: "Commencer",
      SpeakToSales: "Parlez à un commercial",
      ReputationManagement: "Gestion de la réputation",
      IncreaseReviews: "Augmentez vos avis clients",
      ExtractInsights: "Extraire les informations clés des avis",
      KeepListingUpToDate: "Gardez votre fiche d'entreprise à jour",
      AnswerCustomers: "Répondez a vos clients 24/7",
      DigitalMarketing: "Marketing digital",
      SEO: "Optimisation et audit SEO",
      Ads: "Promouvoir sur Google et Meta",
      ContentGeneration: "Générer du contenu engageant",
      EmailSMS: "Promouvoir par email et SMS",
      BusinessAutomation: "Automatisation",
      GenAI: "Créer des solutions d'IA génératives",
      POS: "Installer des systèmes POS",
      OnlineBooking: "Installer des solutions de réservation",
      InventoryManagement: "Integrer des systemes de gestion",
      OurStory: "Notre histoire",
      OurStoryP1:
        "Fondé par deux technologues experts, OutrankThem réunit plus de 20 ans d'expérience dans des rôles techniques de premier plan au sein de grandes entreprises et de startups aux États-Unis et au Canada. Notre essence réside dans la résolution de problèmes commerciaux complexes par des solutions logicielles créatives, ancrées dans une approche basée sur les données pour des résultats mesurables.",
      OurStoryP2:
        "Nous sommes déterminés à faire avancer votre entreprise, en vous assurant de garder une longueur d'avance dans le paysage concurrentiel.",
      TrustedByClients: "Ils nous ont fait confiance.",
      PaTestimonialTitle: "Transformer les Défis en Triomphes",
      PaTestimonialP1:
        "En tant que PDG de Parts Avatar, ma gratitude envers OutrankThem est immense pour leurs apports considérables durant ces derniers 10 mois. Dans une période cruciale où nous préparions la vente de l'entreprise, leurs innovations ont nettement renforcé nos propositions et dynamisé le trafic sur notre site web.",
      PaTestimonialP2:
        "Leur expertise à rendre simples les problématiques complexes, illustrée par l'astucieux projet de notes de compatibilité et l'amélioration exceptionnelle de notre technologie de recherche, a largement dépassé les standards des mastodontes du secteur. Notre campagne SEO stratégique, en améliorant considérablement notre visibilité en ligne, a rendu nos services incontournables pour nos clients.",
      PaTestimonialP3:
        "Merci, OutrankThem, d'avoir été une partie inestimable de notre parcours vers le succès. Votre partenariat a été un facteur clé de nos réalisations.",
      CeoPartsAvatar: "PDG de Parts Avatar",
      CapTestimonialTitle: "Renaissance Numérique",
      CapTestimonialP1:
        "En tant que Directeur Général chez Mont Spartel, je suis profondément reconnaissant de l'impact transformateur qu'OutrankThem a eu sur la présence digitale du phare de Cap Spartel. La refonte globale de notre site web pour y inclure plusieurs langues, accompagnée d'une amélioration notable du design et du référencement SEO, a généré 36 000 impressions et nous a propulsés dans le top 5 des classements de recherche organique de Google.",
      CapTestimonialP2:
        "Leur gestion méticuleuse de notre réputation en ligne, incluant des audits, des réponses aux avis, ainsi que l'enrichissement du contenu avec des photos et vidéos, a revitalisé notre image et attiré davantage de visiteurs.",
      CapTestimonialP3:
        "De plus, le lancement de notre nouveau compte Instagram, doté de photographies et de contenus supérieurs, a considérablement augmenté l'engagement, marquant une nouvelle ère d'interaction numérique pour Mont Spartel.",
      ManagingDirectorMontSpartel: "Directeur Général de Mont Spartel",
      PaBlogPostOverview:
        "Découvrez comment le partenariat stratégique de Parts Avatar avec OutrankThem a mené à une croissance sans précédent et à une innovation numérique dans notre dernier article de blog.",
      ReadMore: "Lire la suite",
      DigitalRenaissancePt1: "Renaissance Numérique Partie 1",
      DigitalRenaissancePt2: "Renaissance Numérique Partie 2",
      CapBlogPostOverviewPt1:
        "Découvrez comment la gestion stratégique de la réputation et l'amélioration numérique d'OutrankThem pour le phare de Cap Spartel ont transformé l'expérience des visiteurs et la présence en ligne en un phare de succès.",
      CapBlogPostOverviewPt2:
        "Apprenez dans notre blog comment la maîtrise d'OutrankThem en marketing digital, la refonte de site web, et la création dynamique d'Instagram ont propulsé l'identité en ligne et l'engagement d'une marque vers de nouveaux sommets.",
      ContactDescription:
        "Vous faites face à un défi? À la recherche d'informations sur nos solutions innovantes? Intéressé par nos stratégies d'affaires sur mesure? Contactez-nous.",
      ContactSubject: "Titre",
      ContactYourMessage: "Comment pouvons-nous vous aider?",
      ContactSubmit: "Envoyer",
      ContactSubmitting: "En cours...",
      ContactSubmitted: "Votre demande a été soumise avec succès!",
      AllRightsReserved: "Tous droits réservés",
    },
  },
};
