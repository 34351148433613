import React from "react";
import { useTranslation } from "react-i18next";

export const Clients = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32 dark:bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 lg:col-span-7">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900 dark:text-white">
          {t("TrustedByClients")}
        </h2>
        <div className="mx-auto mt-5 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5 dark:text-white">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="/amazon.png"
            alt="Amazon"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="/microsoft.png"
            alt="Microsoft"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="/DRW.png"
            alt="DRW"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src="/assurance.png"
            alt="Assurance"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src="/partsavatar.png"
            alt="Statamic"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};
