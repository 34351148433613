import React, { useState } from "react";
import axios from "axios";
import { Label } from "../effects/label";
import { Input } from "../effects/input";
import { LabelInputContainer } from "../effects/labelContainer";
import { TextArea } from "../effects/textarea";
import { useTranslation } from "react-i18next";

export const Contact = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = (email, subject, message) => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    if (subject.trim() === "") {
      alert("Subject cannot be empty.");
      return false;
    }

    if (message.trim() === "") {
      alert("Message cannot be empty.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm(email, subject, message)) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post("/api/processContactForm", {
        email,
        subject,
        message,
      });
      console.log(response.data);
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900 -mt-16" id="contact">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        {!isSubmitted && (
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            {t("ContactDescription")}
          </p>
        )}
        {isSubmitted ? (
          <p className="text-center text-2xl text-white dark:text-white">
            {t("ContactSubmitted")}
          </p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-8">
            <LabelInputContainer>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </LabelInputContainer>
            <LabelInputContainer>
              <Label htmlFor="subject">{t("ContactSubject")}</Label>
              <Input
                id="subject"
                placeholder={t("ContactSubject")}
                type="text"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </LabelInputContainer>
            <LabelInputContainer>
              <Label htmlFor="subject">Message</Label>
              <TextArea
                id="message"
                placeholder={t("ContactYourMessage")}
                required
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </LabelInputContainer>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-900"
            >
              {isSubmitting ? t("ContactSubmitting") : t("ContactSubmit")}
            </button>
          </form>
        )}
      </div>
    </section>
  );
};
