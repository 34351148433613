"use client";
import React from "react";
import { SparklesCore } from "../effects/sparkles";
import { useTranslation } from "react-i18next";

export function SparklesPreview() {
  const { t } = useTranslation();
  return (
    <div className="dark:bg-gray-900 relative w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
      <div className="w-full absolute inset-0 h-screen">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
      </div>
      <div className="py-4 px-4 mx-auto max-w-screen-xl text-center lg:py-8 lg:px-6">
        <div className="mx-auto max-w-screen-md">
          <h2 className="pb-10 mb-4 text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white dark:bg-gradient-to-r dark:from-rose-400 dark:via-fuchsia-500 dark:to-indigo-500 dark:bg-[length:100%_6px] dark:bg-no-repeat dark:bg-bottom">
            {t("Testimonials")}
          </h2>
        </div>
        <div className="-mt-5 grid mb-8 lg:mb-12 lg:grid-cols-2">
          <figure className="flex flex-col justify-center items-center p-8 text-left bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {t("PaTestimonialTitle")}
              </h3>
              <p className="my-4">{t("PaTestimonialP1")}</p>
              <p className="my-4">{t("PaTestimonialP2")}</p>
              <p className="my-4">{t("PaTestimonialP3")}</p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div>Sherdeep S.</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {t("CeoPartsAvatar")}
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-left bg-gray-50 border-b border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {t("CapTestimonialTitle")}
              </h3>
              <p className="my-4">{t("CapTestimonialP1")}</p>
              <p className="my-4">{t("CapTestimonialP2")}</p>
              <p className="my-4">{t("CapTestimonialP3")}</p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div>Najat G.</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {t("ManagingDirectorMontSpartel")}
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
}

export const Testimonials = () => {
  return (
    <section className="texbg-white dark:bg-gray-900 pt-10" id="testimonials">
      <SparklesPreview />
    </section>
  );
};
