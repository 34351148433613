import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Home } from "./home/home";
import { PartsAvatar } from "./blog/pa";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS } from "./translations/Translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: TRANSLATIONS,
    // lng: "fr",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/blog/pa",
    element: <PartsAvatar />,
  },
]);
