"use client";
import React from "react";
import { TypewriterEffectSmooth } from "../effects/typewriter";

export function ReadyToWorkWithUs() {
  const words = [
    {
      text: "Are",
    },
    {
      text: "you",
    },
    {
      text: "ready",
    },
    {
      text: "to",
    },
    {
      text: "outrank",
      className: "text-yellow-400 dark:text-yellow-400",
    },
    {
      text: "them",
    },
    {
      text: "?",
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center dark:bg-gray-900">
      <TypewriterEffectSmooth words={words} />
    </div>
  );
}
