import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentYear } from "../../utils/helper";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="dark:bg-gray-900">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {getCurrentYear()} OutrankThem™ . {t("AllRightsReserved")}
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="#home" className="hover:underline me-4 md:me-6">
              {t("Home")}
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:underline">
              {t("Contact")}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
