"use client";
import React from "react";
import { useTranslation } from "react-i18next";

export const Ethos = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-white dark:bg-gradient-to-r dark:from-gray-900 dark:to-gray-700">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            {t("OurStory")}
          </h2>
          <p className="mb-4">{t("OurStoryP1")}</p>
          <p>{t("OurStoryP2")}</p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img
            className="w-full rounded-lg"
            src="/office_vertical.png"
            alt="office content 1"
          />
          <img
            className="mt-4 w-full lg:mt-10 rounded-lg"
            src="/client_vertical.jpg"
            alt="office content 2"
          />
        </div>
      </div>
    </section>
  );
};
