import React from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { NavigationBar } from "./sections/navigationBar";
import { Spotlight } from "./effects/spotlight";
import { Ethos } from "./sections/ethos";
import { Hero } from "./sections/hero";
import { Testimonials } from "./sections/testimonials";
import { Portfolio } from "./sections/portfolio";
import { ReadyToWorkWithUs } from "./sections/readyToWorkWithUs";
import { Contact } from "./sections/contact";
import { Footer } from "./sections/footer";
import { Clients } from "./sections/clients";

export const Home = () => {
  return (
    <div className="text-left dark">
      <NavigationBar />
      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="white"
      />
      <Hero />
      <Ethos />
      <Clients />
      <Testimonials />
      <Portfolio />
      <ReadyToWorkWithUs />
      <Contact />
      <Footer />
      <SpeedInsights />
    </div>
  );
};
